$(document).ready(function () {
    $('.language').on('change', function (e) {
        e.preventDefault();
        console.log($(this).children("option:selected").val());
        document.location.replace($(this).children("option:selected").val());
    })
    $("#navbar .home .aji-story").on('click', function(event){
        event.preventDefault();
        var id =  $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(id).offset().top-100
        }, 1000);
    });
    $('#menu').animate({'bottom':0, 'opacity':1}, 350);
    $('.top-banner.hideme').animate({'margin-top':0, 'opacity':1}, 850);
    $('.top-banner.hideme').addClass('show');

    // responsive menu //
    $('.menu-icon').click(function (e) {
        e.preventDefault();
        $('.menu-icon').toggleClass('on');
        $('.re-nav').toggleClass('show');
        $('.re-top-icon').toggleClass('hide');
    })
    $('.re-nav').click(function() {
        $('.re-nav').removeClass('show');
        $('.menu-icon').removeClass('on');
    });
    var menuListener = function() {

        if ($(window).scrollTop() < 50) {
            $('body').removeClass('scroll');
        } else {
            $('body').addClass('scroll');
        }

    }

    /*$('.body-mobile-desktop').on('click', function(e){
        e.preventDefault();
        if($('body').hasClass('active')){
            location.reload();
        }else{
            $('body').addClass('active');
            $('meta[name=viewport]').remove();
            $(this).text('返回手機版');
        }
    })*/

    $(window).scroll(function() {

        menuListener();

        // hideme effect animate //
        $('.hideme').each(function(i) {

            if ($(window).width() > 1025) {
                var top_of_object = $(this).offset().top + 250
            } else {
                var top_of_object = $(this).offset().top + 10
            };

            var top_of_window = $(window).scrollTop() + $(window).height();

            if (top_of_window > top_of_object) {
                $(this).animate({'margin-top':0, 'opacity':1}, 400);
                $(this).addClass('show');
            }

        });

    });
});

